<template>
  <div class="main-div">
    <div class="top">
      <div class="contact-image">
        <img src="../../../../assets/images/data-processing-icon.svg" alt="" />
        <p>Contact Enrichment</p>
      </div>
      <!-- <div v-if="showTable" class="refresh-btn">
          <button
            class="secondary-btn-outline"
            type="submit"
            @click="refreshContactList"
          >
            Refresh
          </button>
          <input
            type="text"
            placeholder="Search..."
            v-on:input="searchContact"
            v-model="searchTerm"
          />
        </div> -->
      <a
        @click="checkWebLogs()"
        style="color: blue; text-decoration: none; cursor: pointer;"
        >WebHook Logs</a
      >
    </div>
    <div class="start-table text-center mb-5 start-enrich">
      <div v-if="!showTable">
        <p>No Enrichment Exist</p>
      </div>
      <table v-if="showTable" class="first-tables table-new table-ceter-align ">
        <thead>
          <tr class="Head_table">
            <th
              class="table_head"
              v-for="(heading, index) in fields"
              :key="index"
            >
              {{ heading.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in finalFileData" :key="index">
            <td>
              {{ index + 1 }}
            </td>

            <td class="R1 text-left">
              <span v-if="data.enrichmentName">{{ data.enrichmentName }}</span>
              <span v-else>-</span>
            </td>
            <td
              class="status-td"
              v-bind:class="
                data.status == 'Completed'
                  ? 'completed'
                  : data.status == 'File not Found'
                  ? 'file_not_found'
                  : data.status == 'In Progress'
                  ? 'in_progress'
                  : data.status == 'Error'
                  ? 'duplicate'
                  : data.status == 'Paused'
                  ? 'duplicate'
                  : ''
              "
            >
              <span>{{ data.status }}</span>
            </td>
            <td class="R1">
              {{ data.created_at ? getDateInformat(data.created_at) : "" }}
            </td>
            <td class="R1">
              {{ data.created_at ? getformatDate(data.created_at) : "" }}
            </td>

            <!-- <td class="R1">
                <span v-if="data.company_premium == 1">
                  <svg
                    width="22"
                    height="17"
                    viewBox="0 0 22 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    v-if="data.status == 'Completed'"
  
                    <path
                      d="M7.41257 12.5216L2.65291 7.76197L0.609131 9.80575L7.41257 16.6092L21.443 2.57881L19.3992 0.535034L7.41257 12.5216Z"
                      fill="#20B89E"
                    ></path></svg
                ></span>
                <span v-if="data.company_premium == 0">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L21 21M21 1L1 21"
                      stroke="#FF0000"
                      stroke-width="2"
                    ></path>
                  </svg>
                </span>
              </td> -->
            <!-- <td class="R1">
                <span v-if="data.company_french == 1">
                  <svg
                    width="22"
                    height="17"
                    viewBox="0 0 22 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    v-if="data.status == 'Completed'"
  
                    <path
                      d="M7.41257 12.5216L2.65291 7.76197L0.609131 9.80575L7.41257 16.6092L21.443 2.57881L19.3992 0.535034L7.41257 12.5216Z"
                      fill="#20B89E"
                    ></path></svg
                ></span>
                <span v-if="data.company_french == 0">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L21 21M21 1L1 21"
                      stroke="#FF0000"
                      stroke-width="2"
                    ></path>
                  </svg>
                </span>
              </td> -->
            <!-- <td class="R1">
                <span v-if="data.company_full == 1">
                  <svg
                    width="22"
                    height="17"
                    viewBox="0 0 22 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    v-if="data.status == 'Completed'"
  
                    <path
                      d="M7.41257 12.5216L2.65291 7.76197L0.609131 9.80575L7.41257 16.6092L21.443 2.57881L19.3992 0.535034L7.41257 12.5216Z"
                      fill="#20B89E"
                    ></path></svg
                ></span>
                <span v-if="data.company_full == 0">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L21 21M21 1L1 21"
                      stroke="#FF0000"
                      stroke-width="2"
                    ></path>
                  </svg>
                </span>
              </td> -->
            <!-- <td class="R1">
                <span v-if="data.deep_traffic == 1">
                  <svg
                    width="22"
                    height="17"
                    viewBox="0 0 22 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    v-if="data.status == 'Completed'"
  
                    <path
                      d="M7.41257 12.5216L2.65291 7.76197L0.609131 9.80575L7.41257 16.6092L21.443 2.57881L19.3992 0.535034L7.41257 12.5216Z"
                      fill="#20B89E"
                    ></path></svg
                ></span>
                <span v-if="data.deep_traffic == 0">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L21 21M21 1L1 21"
                      stroke="#FF0000"
                      stroke-width="2"
                    ></path>
                  </svg>
                </span>
              </td> -->

            <td class="R1">{{ data.total_contacts }}</td>
            <td>
              <span class="processed">{{ data.processed_contacts }}</span> /
              <span class="errorProcess">{{ data.error_contacts }}</span>
            </td>
            <td class="R1">{{ data.not_processed_contacts }}</td>
            <!-- <td class="R1">
                {{ data.last_processed_email }}
              </td> -->
            <!-- <td>
              <span
                class="custom-tooltipContact Enrichment Logs view-eye-style"
                @click="handleRouteChange(data, $event)"
              >
                <img
                  width="24"
                  src="../../../../assets/images/icon_eye.png"
                  alt="icon_eye"
                />
              </span>
            </td> -->
            <td>
              <button class="secondary-btn-outline border-0 px-2">
                <span
                  class="custom-tooltipContact Enrichment Logs view-eye-style"
                  @click="handleRouteChange(data, $event)"
                >
                  <img
                    width="24"
                    src="../../../../assets/images/icon_eye.png"
                    alt="icon_eye"
                  />
                </span>
              </button>
              <button
                @click="modelEditOpen(data)"
                class="secondary-btn-outline border-0 px-2"
              >
                <img
                  width="24"
                  src="../../../../assets/images/icon_edit.png"
                  alt=""
                />
              </button>
              <button
                v-if="data.status !== 'Completed' && data.status !== 'Error'"
                class="secondary-btn-outline playandpausebtn border-0 px-2"
                v-bind:class="{
                  InRed: data.status == 'In Progress',
                  InGreen: data.status == 'Paused',
                }"
                @click="modelOpen(data)"
              >
                <span class="playandpausebtn-icon">
                  <!-- {{
                      data.status == "In Progress"
                        ? "Pause"
                        : data.status == "Paused"
                        ? "Start"
                        : "Completed"
                    }} -->
                  <img
                    width="24"
                    v-if="data.status === 'In Progress'"
                    src="../../../../assets/images/pause-icon.svg"
                    alt=""
                  />
                  <img
                    width="24"
                    v-else-if="data.status === 'Paused'"
                    src="../../../../assets/images/icon_start.png"
                    alt=""
                  />
                  <span v-else>Completed</span>
                </span>
              </button>
              <button v-else class="invisible"></button>
            </td>
          </tr>
        </tbody>
      </table>
      <modal name="my-modal" class="modaldata start-modal">
        <div class="enrich-head">
          <img
            aria-hidden="true"
            @click="closeModal"
            src="../../../../assets/images/popup-close.png"
          />
        </div>
        <div class="enrichmodal newenrichmodal ">
          <h4><span class="text-color fw-bolder">Are you sure</span><br /></h4>
          <p class="modal-subheading enrich-text">
            You want to {{ this.actionText }}
          </p>
          <div class="action_button" style="text-align: center;  ">
            <button
              @click="closeModal"
              class="action_perform secondary-btn-outline"
            >
              No
            </button>
            <button
              @click="enrichAction"
              class="action_perform secondary-btn-outline"
            >
              Yes
            </button>
          </div>
        </div>
      </modal>
      <!-- ///////Edit the Enrich name Modal/// -->

      <div v-if="this.myEditmodal" class="modaldata">
        <div class="modal_outer_div" @click="closeEditModal()"></div>
        <div class="company-new">
          <div class="enrichmodalEDIT">
            <div class="enrich-head">
              <img
                aria-hidden="true"
                @click="closeEditModal"
                src="../../../../assets/images/popup-close.png"
              />
              <!-- <i
                class="fa fa-times"
                aria-hidden="true"
                @click="closeEditModal"
              ></i> -->
            </div>
            <form @submit.prevent="submitForm">
              <p><span class="text-color">Edit Enrichment Name</span><br /></p>
              <h3>Enrichment Name</h3>
              <input
                type="text"
                v-model="enrichName"
                name="enrichName"
                placeholder="Enter Enrichment Name"
              />

              <!-- Error message for required field -->
              <p
                v-if="this.formSubmitted && !this.enrichName"
                class="error-message"
              >
                Enrichment Name is required
              </p>

              <div class="action_button text-right">
                <button
                  @click="closeEditModal"
                  class="action_perform secondary-btn-outline"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  class="action_perform secondary-btn-outline save-btn"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- ///////Edit the Enrich name Modal ends/// -->
    </div>
    <!-- <div v-if="showTable" class="pagination">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        @change="page($event)"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <div></div>
    </div> -->
    <div v-if="showTable" class="pagination ">
      <div class="main-page">
        <p>
          Displaying <strong> {{ this.finalFileData.length }} </strong> out of
          <strong>{{ this.PagesCount * 10 }}</strong>
        </p>
        <div>
          <span>
            <strong class="mr-3"
              >{{ currentPage }} - {{ this.finalFileData.length }}
            </strong></span
          >
          <button
            :class="
              currentPage === 1
                ? 'disableContactEnrich previous-btn'
                : 'activeContactEnrich previous-btn'
            "
            @click="page(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <img src="../../../../assets/images/arrow-left-icon.png" alt="" />
          </button>
          <button
            :class="
              PagesCount === currentPage
                ? 'disableContactEnrich next-btn'
                : 'activeContactEnrich next-btn'
            "
            @click="page(currentPage + 1)"
            :disabled="this.PagesCount === this.currentPage"
          >
            <img src="../../../../assets/images/right-icon.png" alt="" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadingService } from "../../common/services/loading.service";
import moment from "moment";
import {
  GET_ENRICH_CONTACT_LIST,
  CONTACT_PROCESSED_STATUS,
  STOP_ENRICHMENT,
  EDIT_ENRICH_NAME,
} from "../../../store/constant/actions.type";
export default {
  name: "enrich-contact",
  components: {},
  data() {
    return {
      fields: [
        { key: "", label: "#" },

        { key: "enrichmentName", label: "Enrichment Name" },
        { key: "status", label: "Status" },
        { key: "date", label: "Date" },
        { key: "time", label: "Start Time" },

        // { key: "companyPremium", label: "Company Premium" },
        // { key: "companyFrench", label: "Company French" },
        // { key: "companyFull", label: "Company Full" },
        // { key: "deepTraffic", label: "Deep Traffic" },
        { key: "totalContact", label: "Total" },
        { key: "processed/error", label: "Processed / Error" },
        { key: "notProcessed", label: "Not Processed" },
        // { key: "lastEmailEnriched ", label: "Last Email Enriched" },
        // { key: "view logs", label: "View Logs" },
        { key: "Action", label: "Action" },
      ],
      finalFileData: [],
      searchTerm: "",
      contactList: [],
      totalRows: 0,
      perPage: 10,
      PagesCount: 0,
      currentPage: 1,
      showTable: true,
      actionUser: {},
      actionText: "",
      UserSelectedEditData: "",
      enrichName: "",
      formSubmitted: false,
      myEditmodal: false,
    };
  },
  computed: {},
  methods: {
    checkWebLogs() {
      const routeUrl = this.$router.resolve({ name: "webhook-logs" }).href;
      window.open(routeUrl, "_blank");
    },
    modelOpen(postData) {
      if (postData.status == "Paused") {
        this.actionText = "start Enrichment";
      } else if (postData.status == "In Progress") {
        this.actionText = "Pause Enrichment";
      } else {
        postData = {};
      }
      this.actionUser = postData;
      this.$modal.show("my-modal");
      this.myEditmodal = false;
    },
    modelEditOpen(editData) {
      if (editData?.enrichmentName) {
        this.enrichName = editData?.enrichmentName;
      }
      this.UserSelectedEditData = editData;
      this.myEditmodal = true;
      this.$modal.hide("my-modal");
    },
    handleRouteChange(data, event) {
      const isCtrlPressed = event.ctrlKey || event.metaKey;
      let id = data.id;
      this.$store.dispatch("changeApitime", 2);
      if (isCtrlPressed) {
        window.open(`/enrich-details-contact/${id}`, "_blank");
      } else {
        this.$router.push({ name: "enrich-details-contact", params: { id } });
        // this.$router.push({ name: "design-enrich-logs", params: { id } });
      }
    },

    page(event) {
      this.currentPage = Number(event);
      loadingService.setloader(true);
      let payload = {
        offset: 10,
        page: this.currentPage,
      };
      this.$store
        .dispatch(GET_ENRICH_CONTACT_LIST, payload)
        .then((response) => {
          loadingService.setloader(false);
          if (response.status == "success" && response.data.data.length > 0) {
            this.finalFileData = response.data.data;
            // this.currentPage = response.data.currentPage;
            for (let i = 0; i < this.finalFileData.length; i++) {
              this.finalFileData[i].created_at = new Date(
                this.finalFileData[i].created_at
              );
            }
          } else {
            loadingService.setloader(false);
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          if (err != undefined) {
            this.flashMessage.error({
              title: "Error",
              message: err.data.description,
            });
          }
        });
    },
    getDateInformat(d) {
      if (d) {
        let date = new Date(d);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let m, dy;
        if (month <= 9) m = "0" + month;
        else m = month;
        if (day <= 9) dy = "0" + day;
        else dy = day;
        return `${year}-${m}-${dy}`;
      } else {
        return null;
      }
    },
    getformatDate(value) {
      let RFCDate = value.toUTCString();
      if (value) {
        return moment(String(RFCDate)).format("hh:mm a");
      }
    },
    enrichAction() {
      this.$modal.hide("my-modal");
      let data = {
        id: this.actionUser.id,
        status: this.actionUser.status,
        file_name: this.actionUser.file_name,
      };

      if (this.actionUser.status == "Paused") {
        this.$store
          .dispatch(CONTACT_PROCESSED_STATUS, data)
          .then((response) => {
            if (response) {
              this.getContactList();
              this.flashMessage.success({
                message: response.result,
              });
            }
          })
          .catch((err) => {
            this.flashMessage.error({
              title: "Error",
              message: err.error,
            });
          });
      } else if (this.actionUser.status == "In Progress") {
        this.$store
          .dispatch(STOP_ENRICHMENT, data)
          .then((response) => {
            if (response.result == "Enrichment Paused Successfully.") {
              this.getContactList();
              this.flashMessage.success({
                message: response.result,
              });
            }
          })
          .catch((err) => {
            this.flashMessage.error({
              title: "Error",
              message: err.error,
            });
          });
      }
    },
    getContactList() {
      loadingService.setloader(true);
      let payload = {
        offset: this.perPage,
        page: this.currentPage,
      };
      this.$store
        .dispatch(GET_ENRICH_CONTACT_LIST, payload)
        .then((response) => {
          loadingService.setloader(false);
          if (response.status == "success" && response.data.data.length > 0) {
            this.finalFileData = response.data.data;
            if (this.finalFileData.length < 1) {
              this.showTable = false;
            }
            this.totalRows = parseInt(response.data.totalPages) * 10;
            this.PagesCount = parseInt(response.data.totalPages);
            for (let i = 0; i < this.finalFileData.length; i++) {
              this.finalFileData[i].created_at = new Date(
                this.finalFileData[i].created_at
              );
            }
          } else {
            loadingService.setloader(false);
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          if (err != undefined) {
            this.flashMessage.error({
              title: "Error",
              message: err.data.description,
            });
          }
        });
    },
    closeModal() {
      this.$modal.hide("my-modal");
    },
    closeEditModal() {
      console.log("cancle");
      this.myEditmodal = false;
      this.enrichName = "";
      this.formSubmitted = false;
    },

    submitForm() {
      this.formSubmitted = true;
      if (!this.enrichName) {
        // Don't submit the form if the enrichName field is empty
        return;
      }
      // Handle form submission, you can perform any required actions here
      // For example, you can call EditEnrichName method here
      this.EditEnrichName();
      this.closeEditModal(); // Close modal after form submission
    },
    EditEnrichName() {
      loadingService.setloader(true);
      let payload = {
        UserSelectedEditData: this.UserSelectedEditData,
        enrichmentName: this.enrichName,
      };
      this.$store
        .dispatch(EDIT_ENRICH_NAME, payload)
        .then((response) => {
          if (response.message == "Enrichment updated Successfully.") {
            this.getContactList();
            loadingService.setloader(false);
            this.flashMessage.success({
              message: response.message,
            });
            this.enrichName = "";
            this.formSubmitted = false;
          } else {
            loadingService.setloader(false);
          }
        })
        .catch((err) => {
          console.log("check the api eror", err);
          loadingService.setloader(false);
          this.flashMessage.error({
            title: "Error",
            message: err.error,
          });
        });
    },

    refreshContactList() {
      // this.getContactList();
    },
  },
  beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
    this.getContactList();
    // this.$store
    // .dispatch()
  },
};
</script>

<style scoped>
body {
  font-family: Semi Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 16px;
}
.top {
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: space-between;
}
.top p {
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  margin: 0px 17px;
  display: inline-block;
  vertical-align: middle;
}
.table_head {
  color: #7a869a;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  /* width: 8% !important; */
}
td {
  align-content: center;
}
/* tr td {
    width: 16%;
  } */
tr.R1 {
  color: #000000;
  width: 14%;
}
.start-table {
  margin-top: 24px;
  overflow-x: auto;
}
.first-tables {
  width: 100%;
  line-height: 45px;
  color: FFFFFF;
}
.start-table.bottom-area.table-custom {
  padding-top: 0;
}
.start-table.bottom-area.table-custom th {
  font-size: 14px;
  line-height: 1.2;
  vertical-align: middle;
}
/* .completed {
      color: #5BB645;
      border-color: #5BB645;
  
  } */
/* .file_not_found {
      color: #DA4E39;
      border-color: #DA4E39;
  } */
.modaldata ::v-deep .vm--modal {
  padding: 30px !important;
}

.start-table.start-enrich .modaldata ::v-deep .vm--modal {
  height: auto !important;
  border-radius: 18px;
}

.action_button {
  margin-top: 60px;
  margin: auto;
  border: none !important;
}
.action_perform {
  /* width: 40%; */

  margin-left: 20px;
}
.action_perform:first-child {
  margin-left: 0;
}

.in_progress {
  color: #5bb645;
}
.duplicate {
  color: #da4e39;
}
.action_button {
  width: 100%;
  padding: 6px 15px;
}
.enrichmodal {
  position: absolute;
  width: 100%;
  top: 56%;
  transform: translateY(-50%);
}

.start-table.start-enrich .enrichmodal {
  position: unset;
  transform: translateY(0%);
}

.enrich-head {
  color: #003172;
  font-weight: 100;
  background: #e6e6e6;
  padding: 6px 6px;
  border-radius: 50px;
  cursor: pointer;
  text-align: right;
}
.modaldata.start-modal.vm--container .enrich-head {
  position: absolute;
  right: 6px;
  top: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.modaldata.start-modal.vm--container .vm--modal {
  width: 640px !important;
  height: 166px !important;
  border-radius: 24px;
}
.modaldata.start-modal.vm--container .secondary-btn-outline {
  padding: 3px 13px;
}

button.activeContactEnrich.previous-btn {
  border-radius: 4px 0px 0px 4px;
}

button.disableContactEnrich.previous-btn {
  border-radius: 4px 0px 0px 4px;
}
button.activeContactEnrich.next-btn {
  border-radius: 4px 0px 0px 4px;
}
button.activeContactEnrich.next-btn {
  border-radius: 0px 4px 4px 0px;
}

.modaldata.start-modal.vm--container p {
  text-align: center;
}

.enrich-head {
  text-align: right;
  /* background: #3f6fcc; */
  padding-right: 9px;
}
.vm--modal {
  width: 640px !important;
  height: 300px !important;
}
.table-new tr td {
  line-height: 25px;
  padding: 15px 0px;
  font-size: 14px;
  font-weight: 500;
}
.table-new tr td.status-td span {
  border-radius: 4px;
  display: inline-block;
  padding: 0px 9px 0px;
  font-size: 12px;
  font-weight: 500;
  max-width: 92px;
  width: 100%;
  text-align: center;
}
th.table_head:nth-child(4) {
  text-align: left;
  padding: 2px 15px;
}

.table-new tr td:nth-child(4) {
  text-align: left;
  padding: 2px 15px;
}
td span svg {
  height: 16px;
  widows: 16px;
}
table.first-tables td:nth-child(3) {
  text-align: left;
  padding: 2px 10px;
}
th.table_head:nth-child(3) {
  text-align: left;
  padding: 2px 10px;
}
th.table_head:first-child {
  width: 84px;
}
td.completed span {
  color: #5bb645;
  border: 1px solid #5bb64524;
  background: #2ca96817;
}
td.file_not_found span {
  color: #da4e39;
  border: 1px solid #da4e39;
}
td.in_progress span {
  color: #64b7d5;
  border: 1px solid #64b7d5;
  background-color: #64b7d521;
}
td.duplicate span {
  border: 1px solid #da4e391a;
  color: #da4e39;
  background-color: #da4e390f;
}
.table-new tr td span.processed {
  color: #00a300;
}
.colorGreen {
  color: #00a300;
}
.table-new tr td span.errorProcess {
  color: #ff0000;
}
.colorRed {
  color: #ff0000;
}
.table-new tr:nth-child(2n) {
  background-color: #10152808;
  border-top: 2px solid #10152808;
  border-bottom: 2px solid #10152808;
}
.first-tables tr.Head_table {
  border-bottom: 1px solid #1015280d;
}
.action_button {
  border: 1px solid #3f8aff;
  border-radius: 18px;
  background: transparent;
  color: #3f8aff;
  font-weight: 600;
  text-transform: capitalize;
  /* padding: 5px 0px; */
  transition: 0.8s all;
}

/* .InRed:hover {
    background: #ff0000;
  } */

/* .InRed:hover span {
    color: #fff;
  } */
.InRed {
  border: 1px solid #ff0000;

  color: #ff0000;
  transition: 0.8s all;
}
/* .InGreen:hover {
    background: #00a300;
  } */
.InGreen:hover span.colorGreen {
  color: #fff;
}
.InGreen {
  color: #00a300;
  transition: 0.8s all;
  border: 1px solid #00a300;
  width: 76px;
  font-size: 14px;
}
.main-div {
  background: #ffffff;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
}
.main-div input {
  width: 68%;
  padding: 8px;
  border: 1px solid #dfe1e6;
  border-radius: 10px 10px 10px 10px;
  outline: none;
  margin-left: 9px;
}
.start-table p {
  font-weight: 600;
  font-size: 26px;
  color: #003172;
  line-height: 32px;
  margin-bottom: 5px;
  text-align: left;
}

.start-table h3 {
  text-align: left;
  font-size: 14px;
  line-height: 12px;
  margin-top: 27px;
  font-weight: 600;
}
.custom-tooltip {
  cursor: pointer;
}
.view-eye-style {
  /* background: #c6c6c6; */
  padding: 11px;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: inline-block;
  line-height: 1;
  cursor: pointer;
  transition: all 0.5s;
}
.first-tables tr.Head_table th.table_head:nth-child(2) {
  color: #000;
  text-align: left;
}
.modal-subheading {
  font-size: 16px;
  font-weight: 500;
}
/* just new start */

/* .main-div
{::v-deep
  {
  .card{
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      border: 0;
      padding: 30px;
      border-radius: 24.808px;
      background: var(--White, #fff);
      box-shadow: 0px 4.962px 12.404px 0px rgba(63, 209, 255, 0.3);
  
      .tabs .card-header {
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 0;
      }
    }

  }
} */

.main-div ::v-deep .tabs .card-header {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.main-div ::v-deep .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 0;
  padding: 30px;
  border-radius: 24.808px;
  background: var(--White, #fff);
  box-shadow: 0px 4.962px 12.404px 0px rgba(63, 209, 255, 0.3);
}

.main-div > .card .tabs .card-header {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

/* .main-div > .card .tabs .card-header .nav.nav-pills {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
  width: 100%;
  max-width: 582px;
} */

/* .main-div > .card .tabs .card-header .nav.nav-pills li.nav-item .nav-link {
  border-radius: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px 12px;
  color: #7a869a;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
} */

.top .action-btn .refresh-btn .secondary-btn-outline:hover {
  background-color: #4f8bff;
  color: #ffff;
  border: 1px solid transparent;
  transition: all 0.4s;
}
.top .action-btn .single-card-box .secondary-btn-outline:hover {
  background-color: #fff;
  color: #4f8bff;
  border: 1px solid #4f8bff;
  transition: all 0.4s;
}
p.modal-subheading.enrich-text {
  font-size: 16px;
  font-weight: 400;
}
.top .action-btn .single-card-box .secondary-btn-outline:hover {
  background-color: #fff;
  color: #4f8bff;
  border: 1px solid #4f8bff;
  transition: all 0.4s;
}

/* 
.main-div
  > .card
  .tabs
  .card-header
  .nav.nav-pills
  li.nav-item
  .nav-link.active {
  background-color: #0031720f;
  color: #003172;
  border: 1px solid #00317230;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;

} */

.first-tables th:nth-child(2) img {
  position: relative;
  top: -1px;
  left: 3px;
}
/* .main-details-card .single-card-box .R1 {
  width: 30px;
  height: 30px;
 
}  */

.first-tables.table-new thead tr {
  background-color: transparent;
}

.table-new tr:nth-child(even) {
  background-color: #7a869a0a;
  border-top: 1px solid #7a869a17;
  border-bottom: 1px solid #7a869a17;
}

.table-new tr td.duplicate span {
  border-radius: 4px;
  background: rgb(210 76 52 / 10%);
  padding: 4px 15px;
  border: 0;
  color: #d24c34;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  border: 1px solid #ff443b33;
}

.table-new tr td.completed span {
  border-radius: 4px;
  background: rgb(32 184 158 / 10%);
  padding: 4px 15px;
  border: 0;
  color: #5aaf4b;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  border: 1px solid #2ca96830;
}

.table-new tr td .databtn {
  border-radius: 4.406px;
  background: unset;
  color: var(--White, #fff);
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 15px;
  width: 40%;
}

.action_button.InGreen {
  background: transparent;
}

button.action_button.InGreen .colorGreen {
  color: #fff;
}

.main-div > .card .search-container {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.main-div > .card .search-container .searchbar {
  width: 94%;
  margin-left: 0;
  padding: 9px 38px;
  border: none;
  background-color: #f7f8fa;
  font-size: 12px;
}

.main-div > .card .search-container .searchicon {
  position: absolute;
  left: 15px;
  top: 6px;
}

.table-new tr td.in_progress span {
  border-radius: 4px;
  background: rgb(79 139 255 / 11%);
  padding: 4px 8px;
  border: 0;
  color: #4f8bff;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
  border: 1px solid #4f8bff29;
}

.leftcards.loaderbox::before {
  border-color: #f0f0f0;
}

.rightchart.skeletonbox {
  height: 100%;
  padding: 0;
  box-shadow: none;
  justify-content: space-between;
}

.rightchart.skeletonbox .skeleton-loder.skeleton-table-h.roundbox {
  height: 350px;
  border-radius: 100%;
  width: 350px;
  position: relative;
  top: 25px;
  left: 45px;
}

.single-card-box .status-td.completed {
  background: rgba(32, 184, 158, 0.2);
  color: #5aaf4b;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.in_progress {
  background: rgba(247, 143, 0, 0.2);
  color: #f9c000;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.duplicate {
  background: rgba(210, 76, 52, 0.2);
  color: #d24c34;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.duplicate.paused {
  background: #b8b8b8;
  color: #000;
}

.innerleftcards .single-card-box {
  margin-bottom: 10px;
}

.main-details-card .single-card-box.body-task-text {
  height: auto;
  padding: 6px 6px;
}

.main-details-card .single-card-box.body-task-text h4 {
  width: 350px;
}

.innerleftcards {
  background: #f6f6f6;
  border: 0.807px solid #d2d2d2;
  border-radius: 4.036px;
}

.innerleftcards .single-card-box {
  background: transparent;
  border: unset;
  padding: 0;
}

.upper-setting-box h6 {
  margin: 0 7px 0 0;
  font-weight: bold;
  font-size: 12px;
}
.upper-setting-box .inner-single-setting span {
  font-size: 12px;
}

.upper-setting-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 15px;
  padding: 5px 24px 16px;
  border: 1px solid #ebedf2;
  background-color: #ebedf233;
}

.inner-single-setting {
  min-width: 155px;
  margin-top: 10px;
}
.rightchart .chart-donut {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 86%;
  height: 57%;
  top: 61px;
  text-align: center;
}
.apexcharts-canvas svg {
  height: 500px;
}
.rightchart .chart-donut foreignObject {
  height: 289px;
}

.inner-single-setting .status-td {
  font-size: 12px;
  padding: 0px 9px;
}

.upper-setting-box p {
  margin: 0;
}

.middle-setting-box .single-card-box {
  background: transparent;
  border: unset;
  width: 47%;
  padding: 0 10px 0 0;
  border-bottom: 1px solid #eee;
}

.middle-setting-box {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  gap: 0px 29px;
}

.middle-setting-box .single-card-box h4 {
  text-align: left;
  font-size: 12px;
}

.main-details-card .bottom-settings-box .single-card-box {
  padding: 0;
  background: transparent;
  border: unset;
  border-bottom: 1px solid #eee;
  margin-bottom: 4px;
}

.main-details-card .bottom-settings-box .single-card-box:last-child {
  margin-bottom: 0px;
  border-bottom: unset;
}
.bottom-settings-box {
  width: 35%;
}

.main-details-card .bottom-settings-box .single-card-box.body-task-text {
  align-items: self-start;
}

.body-task-text h4 {
  text-align: left;
}

.body-task-text p {
  text-align: right;
}

.main-details-card .error-message-main .single-card-box {
  background: transparent;
  padding: 5px;
  border: 1px solid #eee;
}

.inner-single-setting .status-td.duplicate {
  border-radius: 3px;
  color: #d24c34;
  font-weight: 400;
  border: 1px solid #edd4ce;
  background: rgb(210 76 52 / 10%);
}

span.modal-subheading {
  font-size: 16px;
}
.new-resultss {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pagination .main-page button.activeContactEnrich {
  background-color: #7a869a73 !important;
}
.toggleLable {
  margin: 0;
  margin-right: 10px;
}

.new-resultss .col-6 {
  flex: 0 100%;
  max-width: 100%;
  justify-content: flex-end;
}

.enrich-head {
  position: absolute;
  right: 12px;
  top: 16px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
}
.enrichmodal.newenrichmodal h4 {
  font-weight: 700;
  font-size: 26px;
  color: #000;
}

.vm--modal {
  width: 640px !important;
  height: 214px !important;
  border-radius: 24px;
}
.secondary-btn-outline {
  padding: 3px 13px;
}
p.modal-subheading {
  text-align: center;
}
/* just new end */
/* .view-eye-style:hover {
    background: #aba9a9;
  } */

@media screen and (max-width: 1280px) {
  th.table_head {
    padding: 2px 37px;
    background-color: #fff;
  }

  .table-new tr td:first-child,
  td:nth-child(2) {
    position: sticky;
    left: 0px;
  }
  .table-new tr th:first-child,
  th:nth-child(2) {
    position: sticky;
    left: 0px;
  }
  .table-new tr th:nth-child(2) {
    left: 62px;
    background-color: #fff;
  }
  /* .table-new tr th:nth-child(3) {
    left: 214px;
  } */

  .table-new tr:nth-child(odd) td:first-child {
    background: #ffffff;
  }

  .table-new tr:nth-child(odd) td:first-child {
    background: #ffffff;
  }

  .table-new tr:nth-child(odd) td:nth-child(2) {
    background: #ffffff;
  }
  .table-new tr:nth-child(odd) td:nth-child(2) {
    background: #ffffff;
  }

  .table-new tr td:nth-child(2) {
    left: 62px;
  }

  .table-new tr:nth-child(odd) {
    background: #ffffff;
  }

  .table-new tr:nth-child(odd) {
    background: #ffffff;
  }

  /* .table-new tr td:nth-child(3) {
    left: 214px;
  } */

  .table-new tr:nth-child(even) td:first-child,
  .table-new tr:nth-child(even) td:nth-child(2),
  .table-new tr:nth-child(even) {
    background: #f8f8f8;
  }
  .table-new tr td:nth-child(4),
  .table-new tr td:last-child {
    display: block;
    width: 169px;
  }
  .table-new tr td {
    padding: 10px 19px !important;
  }
  th.table_head {
    padding: 2px 37px 10px 21px;
  }
}
@media screen and (max-width: 600px) {
  th.table_head {
    padding: 4px 26px;
  }
  .table-new tr th:first-child,
  th:nth-child(2) {
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
    width: 27px;
  }
  .main-div {
    padding: 30px 7px;
  }
  .table-new tr th:nth-child(2) {
    left: 35px;
    background-color: #fff;
  }
  /* .table-new tr th:nth-child(3) {
    left: 142px;
  } */
  .table-new tr td:first-child,
  td:nth-child(2) {
    position: -webkit-sticky;
    position: sticky;
    left: 0px;
  }
  .table-new tr td:nth-child(2) {
    left: 46px;
    width: 23px !important;
  }
  .top p {
    font-size: 18px;
  }
  /* .table-new tr td:nth-child(3) {
    left: 148px;
  } */
  .table-new tr td {
    font-size: 12px;
  }
  .table_head {
    font-size: 12px;
  }
}

.table-new tr td {
  padding: 10px 10px;
}
.modal_outer_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.modaldata .enrichmodalEDIT {
  width: 100%;
  max-width: 550px;
  margin: 24px auto;
  box-shadow: 0 47px 80px rgb(0 0 0/23%);
  padding: 20px 20px;
  padding-top: 37px;
  background: rgb(255 255 255);
  position: absolute;
  top: 43%;
  transform: translateY(-50%);
  left: -12%;
  right: 0;
  z-index: 2;
  border-radius: 24px;
  z-index: 99;
  overflow: hidden;
}
.modaldata .enrichmodalEDIT .action_button {
  margin-top: 20px;
}
.modaldata .enrichmodalEDIT .error-message {
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  line-height: 20px;
  text-align: left;
}
.modaldata .enrichmodalEDIT input {
  width: 100%;
  margin: 0;
  margin-top: 0px;
  background-color: #f8f8fa;
}
/* .secondary-btn-outline:hover img {
    filter: brightness(10.5);
  } */
.modaldata .enrichmodalEDIT .enrich-head {
  position: absolute;
  top: 14px;
  right: 16px;
  padding: 5px 5px;
}
.pagination .main-page button.activeContactEnrich img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(8%) saturate(7500%)
    hue-rotate(355deg) brightness(103%) contrast(110%);
}
.modaldata .enrichmodalEDIT .action_button .secondary-btn-outline {
  padding: 3px 13px;
  border-radius: 9px;
  margin-left: 10px;
}

button.action_perform.secondary-btn-outline.save-btn {
  background-color: #3f8aff;
  color: #fff;
  border: none;
}

.pagination .main-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pagination .main-page button {
  background-color: rgba(16, 21, 40, 0.10980392156862745);
  border: none;
  /* border-radius: 2px; */
  width: 28px;
  min-height: 28px;
}
.pagination .main-page button.activeContactEnrich {
  background-color: #7a869a73 !important;
}

button.playandpausebtn .playandpausebtn-icon img {
  height: 25px;
}
button.playandpausebtn {
  padding: 0;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

/* .main-div > .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 0;
  padding: 30px;
  border-radius: 24.808px;
  background: var(--White, #fff);
  box-shadow: 0px 4.962px 12.404px 0px rgba(63, 209, 255, 0.3);
} */

/* .main-div > .card .tabs .card-header {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
} */

/* .main-div > .card .tabs .card-header .nav.nav-pills {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
  width: 100%;
  max-width: 582px;
} */

/* .main-div > .card .tabs .card-header .nav.nav-pills li.nav-item .nav-link {
  border-radius: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px 12px;
  color: #7a869a;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
} */
.top .action-btn .refresh-btn .secondary-btn-outline:hover {
  background-color: #4f8bff;
  color: #ffff;
  border: 1px solid transparent;
  transition: all 0.4s;
}
.top .action-btn .single-card-box .secondary-btn-outline:hover {
  background-color: #fff;
  color: #4f8bff;
  border: 1px solid #4f8bff;
  transition: all 0.4s;
}

.top .action-btn .single-card-box .secondary-btn-outline:hover {
  background-color: #fff;
  color: #4f8bff;
  border: 1px solid #4f8bff;
  transition: all 0.4s;
}

.main-div
  > .card
  .tabs
  .card-header
  .nav.nav-pills
  li.nav-item
  .nav-link.active {
  background-color: #0031720f;
  color: #003172;
  border: 1px solid #00317230;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  /* max-width: 131px; */
}
.first-tables th:nth-child(2) img {
  position: relative;
  top: -1px;
  left: 3px;
}
/* .main-details-card .single-card-box .R1 {
  width: 30px;
  height: 30px;
 
}  */

.first-tables.table-new thead tr {
  background-color: transparent;
}

.table-new tr:nth-child(even) {
  background-color: #7a869a0a;
  border-top: 1px solid #7a869a17;
  border-bottom: 1px solid #7a869a17;
}

.table-new tr td.duplicate span {
  border-radius: 4px;
  background: rgb(210 76 52 / 10%);
  padding: 4px 15px;
  border: 0;
  color: #d24c34;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  border: 1px solid #ff443b33;
}

.table-new tr td.completed span {
  border-radius: 4px;
  background: rgb(32 184 158 / 10%);
  padding: 4px 15px;
  border: 0;
  color: #5aaf4b;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  border: 1px solid #2ca96830;
}

.table-new tr td .databtn {
  border-radius: 4.406px;
  background: unset;
  color: var(--White, #fff);
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 15px;
  width: 40%;
}

.action_button.InGreen {
  background: transparent;
}

button.action_button.InGreen .colorGreen {
  color: #fff;
}

.main-div > .card .search-container {
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.main-div > .card .search-container .searchbar {
  width: 94%;
  margin-left: 0;
  padding: 9px 38px;
  border: none;
  background-color: #f7f8fa;
  font-size: 12px;
}

.main-div > .card .search-container .searchicon {
  position: absolute;
  left: 15px;
  top: 6px;
}

.table-new tr td.in_progress span {
  border-radius: 4px;
  background: rgb(79 139 255 / 11%);
  padding: 4px 8px;
  border: 0;
  color: #4f8bff;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.366px;
  letter-spacing: -0.104px;
  text-transform: capitalize;
  border: 1px solid #4f8bff29;
}

.leftcards.loaderbox::before {
  border-color: #f0f0f0;
}

.rightchart.skeletonbox {
  height: 100%;
  padding: 0;
  box-shadow: none;
  justify-content: space-between;
}

.rightchart.skeletonbox .skeleton-loder.skeleton-table-h.roundbox {
  height: 350px;
  border-radius: 100%;
  width: 350px;
  position: relative;
  top: 25px;
  left: 45px;
}

.single-card-box .status-td.completed {
  background: rgba(32, 184, 158, 0.2);
  color: #5aaf4b;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.in_progress {
  background: rgba(247, 143, 0, 0.2);
  color: #f9c000;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.duplicate {
  background: rgba(210, 76, 52, 0.2);
  color: #d24c34;
  width: auto;
  text-align: center;
  border-radius: 26.049px;
  border: 0;
  padding: 4px 8px;
  font-size: 12.404px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.366px;
  letter-spacing: -0.104px;
}

.single-card-box .status-td.duplicate.paused {
  background: #b8b8b8;
  color: #000;
}

.innerleftcards .single-card-box {
  margin-bottom: 10px;
}

.main-details-card .single-card-box.body-task-text {
  height: auto;
  padding: 6px 6px;
}

.main-details-card .single-card-box.body-task-text h4 {
  width: 350px;
}

.innerleftcards {
  background: #f6f6f6;
  border: 0.807px solid #d2d2d2;
  border-radius: 4.036px;
}

.innerleftcards .single-card-box {
  background: transparent;
  border: unset;
  padding: 0;
}

.upper-setting-box h6 {
  margin: 0 7px 0 0;
  font-weight: bold;
  font-size: 12px;
}
.upper-setting-box .inner-single-setting span {
  font-size: 12px;
}

.upper-setting-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 15px;
  padding: 5px 24px 16px;
  border: 1px solid #ebedf2;
  background-color: #ebedf233;
}

.inner-single-setting {
  min-width: 155px;
  margin-top: 10px;
}
.rightchart .chart-donut {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 86%;
  height: 57%;
  top: 61px;
  text-align: center;
}
.apexcharts-canvas svg {
  height: 500px;
}
.rightchart .chart-donut foreignObject {
  height: 289px;
}

.inner-single-setting .status-td {
  font-size: 12px;
  padding: 0px 9px;
}

.upper-setting-box p {
  margin: 0;
}

.middle-setting-box .single-card-box {
  background: transparent;
  border: unset;
  width: 47%;
  padding: 0 10px 0 0;
  border-bottom: 1px solid #eee;
}

.middle-setting-box {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  gap: 0px 29px;
}

.middle-setting-box .single-card-box h4 {
  text-align: left;
  font-size: 12px;
}

.main-details-card .bottom-settings-box .single-card-box {
  padding: 0;
  background: transparent;
  border: unset;
  border-bottom: 1px solid #eee;
  margin-bottom: 4px;
}

.main-details-card .bottom-settings-box .single-card-box:last-child {
  margin-bottom: 0px;
  border-bottom: unset;
}
.bottom-settings-box {
  width: 35%;
}

.main-details-card .bottom-settings-box .single-card-box.body-task-text {
  align-items: self-start;
}

.body-task-text h4 {
  text-align: left;
}

.body-task-text p {
  text-align: right;
}

.main-details-card .error-message-main .single-card-box {
  background: transparent;
  padding: 5px;
  border: 1px solid #eee;
}

.inner-single-setting .status-td.duplicate {
  border-radius: 3px;
  color: #d24c34;
  font-weight: 400;
  border: 1px solid #edd4ce;
  background: rgb(210 76 52 / 10%);
}

span.modal-subheading {
  font-size: 16px;
}
.new-resultss {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pagination .main-page button.activeContactEnrich {
  background-color: #7a869a73 !important;
}
.toggleLable {
  margin: 0;
  margin-right: 10px;
}

.new-resultss .col-6 {
  flex: 0 100%;
  max-width: 100%;
  justify-content: flex-end;
}

.enrich-head {
  position: absolute;
  right: 12px;
  top: 16px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
}
.enrichmodal.newenrichmodal h4 {
  font-weight: 700;
  font-size: 26px;
  color: #000;
}
.vm--modal {
  width: 640px !important;
  height: 214px !important;
  border-radius: 24px;
}
.secondary-btn-outline {
  padding: 3px 13px;
}
p.modal-subheading {
  text-align: center;
}

@media screen and (max-width: 1550px) {
  .middle-setting-box {
    width: 100%;
  }
  .main-left-card,
  .rightchart {
    display: block;
  }
  .bottom-settings-box {
    width: 100%;
    margin-top: 11px;
  }
  .leftcards {
    width: 70%;
  }
  .main-div > .card .tabs .card-header .nav.nav-pills {
    width: 73%;
  }
}

@media screen and (max-width: 1280px) {
  .main-details-card {
    display: block;
    padding-bottom: 1px;
  }
  .main-left-card,
  .rightchart {
    min-height: 247px;
  }
  .leftcards {
    width: 100%;
  }
  .rightchart {
    width: 100%;
  }
  .rightchart .chart-donut {
    width: 40%;
  }
  .leftcards {
    margin-top: 109px;
  }
  .jobSwitchClass {
    width: 68%;
    position: unset;
  }
  .main-div > .card .tabs .card-header .nav.nav-pills {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
  }
  .top p {
    font-size: 24px;
    margin: 10px 5px 4px;
  }
  .top {
    display: block;
  }
  .action-btn {
    margin-top: 8px;
    justify-content: left;
  }
  table.first-tables.table-new {
    width: 1186px;
  }
  .table-new tr td {
    padding: 10px 3px;
  }
  .rightchart.skeletonbox .skeleton-loder.skeleton-table-h.roundbox {
    max-width: 350px;
    top: 25px;
    left: 29%;
    right: 50%;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .rightchart .chart-donut {
    margin: auto;
    width: 68%;
  }
  .upper-setting-box {
    display: block;
  }
  .inner-single-setting {
    min-width: 155px;
    margin-top: 17px;
  }
  .middle-setting-box {
    display: block;
  }
  .middle-setting-box .single-card-box {
    width: 100%;
  }
  .bottom-settings-box {
    width: 100%;
  }
  .jobSwitchClass {
    width: 100%;
  }
  .leftcards {
    margin-top: 29px;
  }

  .main-div > .card .tabs .card-header .nav.nav-pills li.nav-item .nav-link {
    padding: 3px 12px;
  }
  .leftcards {
    width: 100%;
    padding: 20px;
  }
  .upper-setting-box {
    padding: 5px 19px 16px;
  }
  .top p {
    font-size: 16px;
  }
  .main-left-card,
  .rightchart {
    min-height: 360px;
  }
  .main-details-card {
    padding-bottom: 0px;
  }
}
</style>
